import React, { useEffect, useState } from "react";

function Footer({ strings }) {
  const [rssFeeds, setRSS] = useState([]);

  useEffect(() => {
    async function fetchRssFeeds() {
      const response = await fetch("rss/index.xml");
      const text = await response.text();
      const parser = new DOMParser();
      const xml = parser.parseFromString(text, "text/xml");
      const items = xml.getElementsByTagName("item");
      const maxAge = new Date();
      maxAge.setMonth(maxAge.getMonth() - 1);
      let rssData = [];
      for (let item of items) {
        let title = item.getElementsByTagName("title").item(0).innerHTML;
        let filename = item.getElementsByTagName("filename").item(0).innerHTML;
        let data = {
          title: title,
          filename: filename,
        };
        rssData.push(data);
      }
      setRSS(rssData);
    }
    fetchRssFeeds();
  }, []);
  // receiveData(passValueDown)
  const linkSection = (
    <p>
      <a
        className="underline"
        target="_blank"
        href="https://www.gnistor.se/"
        rel="noopener noreferrer"
      >
        gnistor.se
      </a>
      <br></br>
      <a
        className="underline"
        target="_blank"
        href="https://gatorna.info/"
        rel="noopener noreferrer"
      >
        gatorna.info
      </a>
      <br></br>
      <a
        className="underline"
        target="_blank"
        href="https://radar.squat.net/"
        rel="noopener noreferrer"
      >
        radar.squat.net
      </a>
      <br></br>
      <a
        className="underline"
        target="_blank"
        href="https://dukop.dk/"
        rel="noopener noreferrer"
      >
        dukop.dk
      </a>
      <br></br>
    </p>
  );
  const rssSection = rssFeeds.map((row, index) => (
    <a target="_blank" href={row.filename} rel="noopener noreferrer">
      {row.title}
      <br></br>
    </a>
  ));

  return (
    <div className="font-atkinson flex flex-col md:grid md:grid-cols-3 gap-16 p-10 text-white bg-[#e29b9b]">
      <div className="flex flex-col leading-5 order-1 md:order-2 text-center md:text-left">
        <h3 className="font-bold mb-2 self-center text-xl">
          {strings.aboutHead}
        </h3>
        <p className="sm:text-pretty">{strings.aboutBody}</p>
        <p className="mt-2">{strings.aboutContact}</p>
        <p className="mt-2">
          {strings.grafikCred}
          <a
            className="underline ml-1"
            target="_blank"
            href="https://www.ruhani.se/"
            rel="noopener noreferrer"
          >
            {strings.ruhani}
          </a>
        </p>
      </div>
      <div className="flex flex-row order-2 justify-between md:contents">
        <div className="relative order-2 md:order-1 flex flex-col mx-auto w-1/3">
          <div
            className="absolute -inset-10 bg-contain bg-no-repeat bg-center z-0 opacity-50 invert brightness-120"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/rss.png)`,
            }}
          />
          <h3 className="relative font-bold z-10 text-xl">RSS</h3>
          <div className="relative z-10">
            <p className="relative underline">{rssSection}</p>
          </div>
        </div>
        <div className="order-3 flex flex-col mx-auto md:items-start">
          <h3 className="font-bold mb-2 text-xl">{strings.srcHead}</h3>
          <div>{linkSection}</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
