import React, { useState, useEffect, useMemo, useCallback } from "react";
import SocialMediaLinks from "./components/SocialMediaLinks";

function Body({ strings, lang, selectedCity }) {
  const [calendar, setCalendar] = useState([]);
  const [tags, setTags] = useState([]);
  const [checkedTags, checkTags] = useState([])
  const [sortColumn, setSortColumn] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const api_url = "api.kamrat.laserjesus.se"

  const apiFetch = useCallback(async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.error("Error in apiFetch:", error);
      throw error;
    }
  }, []);

  const getTags = useCallback(async () => {
    try {
      const tagLang = ["en", "eng"].includes(lang) ? "tag_en" : "tag";
      const tagData = await apiFetch(`https://${api_url}/tags`);
      setTags(tagData.map((tag) => ({ id: tag.id, tag: tag[tagLang] })));
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  }, [lang, apiFetch]);

  const getCalendar = useCallback(async () => {
    try {
      const tagIds = checkedTags.join(",");
      const queryParams = tagIds ? { tags: tagIds } : {};
      const query = new URLSearchParams(queryParams).toString();
      const url = query
        ? `https://${api_url}/calendar?${query}`
        : `https://${api_url}/calendar`;
      console.log(url)
      const calendarData = await apiFetch(url);
      setCalendar(
        calendarData.map((event) => ({
          timestamp: Date.parse(event.event_time).toString(),
          date: Intl.DateTimeFormat("sv-SE", {
            day: "numeric",
            month: "numeric",
            hour: "numeric",
            minute: "numeric",
          }).format(Date.parse(event.event_time)),
          eventName:
            event.organizer && !event.title.includes(event.organizer)
              ? `${event.organizer} anordnar ${event.title}`
              : event.title,
          url: event.url,
          location: event.location,
          cancelled: event.cancelled,
        }))
      );
    } catch (error) {
      console.error("Error fetching calendar:", error);
    }
  }, [checkedTags, apiFetch]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  useEffect(() => {
    getCalendar();
  }, [getCalendar]);

  const sortedCalendar = useMemo(() => {
    return [...calendar].sort((rowA, rowB) => {
      const col =
        sortColumn === 0
          ? "timestamp"
          : sortColumn === 1
          ? "eventName"
          : "location";
      const comparison = rowA[col].localeCompare(rowB[col], undefined, {
        numeric: true,
      });
      return sortOrder === "asc" ? comparison : -comparison;
    });
  }, [calendar, sortColumn, sortOrder]);

  // Render the table header row
  const headerRow = (
    <tr>
      <th
        className="bg-[#e29b9b] text-white cursor-pointer hover:text-[#fce2e2] text-2xl p-2"
        onClick={() => handleSort(0)}
      >
        {strings.timeHead}
      </th>
      <th
        className="bg-[#e29b9b] text-white cursor-pointer hover:text-[#fce2e2] text-2xl p-2"
        onClick={() => handleSort(1)}
      >
        {strings.eventHead}
      </th>
      <th
        className="bg-[#e29b9b] text-white cursor-pointer hover:text-[#fce2e2] text-2xl p-2"
        onClick={() => handleSort(2)}
      >
        {strings.locationHead}
      </th>
    </tr>
  );

  // Render the table data rows
  const dataRows = sortedCalendar.map((row, index) => {
    var tableRow = "";
    if (
      selectedCity.length === 0 ||
      selectedCity === row.location ||
      selectedCity === "allCities"
    ) {
      tableRow = (
        <tr
          cancelled={row.cancelled}
          key={index}
          onClick={() => openLink(row.url)}
          className="even:bg-[#fce2e2] odd:bg-[#fcc3c3] cursor-pointer hover:bg-[#f59a9a]"
        >
          <td
            className="p-2 border border-[#2c2c2c]  font-atkinson whitespace-nowrap"
            id="dateCell"
          >
            {row.date}
          </td>
          <td className="p-2 border border-[#2c2c2c]">{row.eventName}</td>
          <td className="p-2 border border-[#2c2c2c]">{row.location}</td>
        </tr>
      );
    }
    return tableRow;
  });

  const checkboxToggle = (event) => {
    console.log("The checkbox was toggled");
    const checkedId = event.target.value;
    if(event.target.checked){
      checkTags([...checkedTags,checkedId])
    }else{
     checkTags(checkedTags.filter(id=>id !== checkedId))
    }
  };


  const tagDiv = tags.map((row, index) => {
    let item = (
      <label htmlFor={row.id}>
        <input
          type="checkbox"
          key={index}
          name={row.id}
          id={row.id}
          value={row.id}
          onChange={(event) => { checkboxToggle(event) }}
        ></input>
        {row.tag}
      </label>
    );
    return item;
  });

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  // Handle sorting of the table columns
  const handleSort = (columnIndex) => {
    if (columnIndex === sortColumn) {
      // Toggle the sort order if the same column is clicked again
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set the sort column and default to ascending order
      setSortColumn(columnIndex);
      setSortOrder("asc");
    }
  };

  return (
    <>
      <div>
        <div className="pt-[200px] flex flex-grow justify-center bg-[#fce2e2]">
          {/* <div className="xl:pb-2000" id="tagDiv"> */}
          {tagDiv}
          {/* </div> */}
        </div>
        <div className="pt-[142px] lg:pt-48 scroll-smooth flex flex-grow justify-center bg-[#fce2e2]">
          <div className="xl:pb-20" id="calendarDiv">
            <table className="max-w-screen-md">
              <thead className="border border-[#2c2c2c]">{headerRow}</thead>
              <tbody>{dataRows}</tbody>
            </table>
          </div>
        </div>
        <SocialMediaLinks strings={strings} />
      </div>
    </>
  );
}

export default Body;
