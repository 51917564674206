function getStrings(lang) {
  const svStrings = {
    kamratPostaren: "Kamratpostaren",
    calHeader: "Kommande händelser",
    timeHead: "När",
    eventHead: "Vad",
    locationHead: "Var",
    srcHead: "Källor",
    aboutHead: "Om Kamratpostaren",
    aboutBody:
      "Kamratpostaren är ett försök att samla alla Sveriges vänsterevenemang på ett ställe.",
    aboutContact:
      "Tankar, frågor och synpunkter skickas till info@kamratpostaren.se",
    twitter: "Kamratpostaren finns också på ",
    and: "och",
    changeLang: "English",
    selectCity: "Välj stad",
    allCities: "Alla",
    howToButton: "Hur lägger jag upp ett evenemang?",
    howToText:
      "Evenemangen på Kamratpostaren hämtas automatiskt från externa källor, och det är därför inte möjligt att publicera evenemang direkt till Kamratpostaren. Istället behöver du publicera evenemangen på någon av kalendrarna som används som källor. En lista över de kalendrarna finns i sidfoten, och mer information om hur du publicerar ett evenemang finns på respektive hemsida.",
    newsletter: "Nyhetsbrev",
    grafikCred: "Kamratpostarens grafik är designad av ",
    RSS: "RSS",
    RSSNotFound: "RSS-lista ej tillgänglig",
    ruhani: "Ruhani",
  };
  const engStrings = {
    kamratPostaren: "Kamratpostaren",
    calHeader: "Upcoming events",
    timeHead: "When",
    eventHead: "What",
    locationHead: "Where",
    srcHead: "Sources",
    aboutHead: "About Kamratpostaren",
    aboutBody:
      "Kamratpostaren is an attempt to gather all of Sweden's leftist events in one place.",
    aboutContact:
      "Thoughts, questions and ideas can be sent to info@kamratpostaren.se",
    twitter: "Kamratpostaren is also on ",
    and: "and",
    changeLang: "Svenska",
    selectCity: "Select city",
    allCities: "All",
    howToButton: "How do I add an event?",
    howToText:
      "As Kamratpostaren is an event aggregation service, it is not possible to add events to the site directly. Instead you need to add events to one of the source calendars. You can find a list of sources in the site footer, and more information about adding events on each site.",
    newsletter: "Newsletter",
    grafikCred: "Kamratpostaren's graphics by ",
    RSS: "RSS",
    RSSNotFound: "RSS list not available",
    ruhani: "Ruhani",
  };
  if (lang === "sv") {
    return svStrings;
  } else {
    return engStrings;
  }
}

export default getStrings;
