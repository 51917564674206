import React from "react";

function SocialMediaLinks({ strings }) {
  return (
    <div className="relative pt-8 pb-8 max-w-[800px] m-auto xl:pt-44 lg:top-0 xl:right-4 xl:fixed w-[70px] flex flex-nowrap xl:flex-col xl:items-center justify-center">
      <a
        href="https://nyhetsbrev.kamratpostaren.se/subscription/form"
        rel="noopener noreferrer"
        target="_blank"
        title={strings.newsletter}
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="mailImg"
          src="assets/mail.png"
          alt={strings.newsletter}
        />
      </a>
      <a
        href="https://www.instagram.com/kamratpostaren/"
        target="_blank"
        rel="noopener noreferrer"
        title="Instagram"
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="instaImg"
          src="assets/instagram.png"
          alt="Instagram"
        />
      </a>
      <a
        href="https://radikal.social/@kamratpostaren"
        rel="noopener noreferrer"
        target="_blank"
        title="Mastodon"
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="mastodonImg"
          src="assets/mastodon.png"
          alt="Mastodon"
        />
      </a>
      <a
        href="https://bsky.app/profile/kamratpostaren.se"
        rel="noopener noreferrer"
        target="_blank"
        title="Bluesky"
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="bskyImg"
          src="assets/bluesky.png"
          alt="Bluesky"
        />
      </a>
      <a
        href="https://twitter.com/kamratpostaren"
        rel="noopener noreferrer"
        target="_blank"
        title="Twitter"
      >
        <img
          className="min-w-[70px] h-auto sm:w-18 sm:h-18"
          id="twitterImg"
          src="assets/twitter.png"
          alt="Twitter"
        />
      </a>
    </div>
  );
}

export default SocialMediaLinks;
